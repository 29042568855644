import React, { useContext } from "react"
import { PageContext } from "../../../context/PageContext"
import { CrmFieldErrors, fieldError } from "../../../hooks/useCrmApi"
import useDatoMicrocopy from "../../../hooks/useDatoMicrocopy"
import Button from "../../atoms/Button"
import Checkbox from "../../atoms/form/Checkbox"
import Fieldset from "../../atoms/form/Fieldset"
import Input from "../../atoms/form/Input"
import LoadingSpinner from "../../atoms/LoadingSpinner"
import UserContactFieldset from "./subcomponents/UserContactFieldset"
// import * as styles from "./HeaterRegistrationForm.module.scss"

export type HeaterRegistrationFormProps = {
  formRef: React.LegacyRef<HTMLFormElement>
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void
  onFilesChange: (files: File[]) => void
  loading: boolean
  errors?: CrmFieldErrors
}

const HeaterRegistrationForm = (props: HeaterRegistrationFormProps) => {
  const { locale } = useContext(PageContext)
  const t = useDatoMicrocopy("formContact", locale)

  const defaultErrorMsg = t("default", "Invalid value", "formErrors")

  const getErrorMsg = (field: string): string | undefined => {
    const error = fieldError(props.errors, field)
    if (error) {
      return error.type ? t(error.type, defaultErrorMsg, "formErrors") : defaultErrorMsg
    }
    return
  }
  return (
    <form ref={props.formRef} onSubmit={props.onSubmit} autoComplete="on" name="heater-registration">
      <Fieldset legend={t("legend.productInfo", "Product information")}>
        {/* TODO: productModel/productType selvitys: onko ne sama vai eri asia? */}
        <Input
          name="productModel"
          label={t("productType.label", "Product make and type")}
          optionalText={t("optional", "optional", "formCommon")}
          helpText={
            <a href={t("productType.helpUrl", "/en/contact/where-to-find-harvia-serial-number")} target="_blank">
              {t("productType.helpText", "How can I tell the model of my heater or stove?")}
            </a>
          }
          required={false}
          errorMsg={getErrorMsg("productModel")}
        />
        <Input
          name="productRegistrationLocation"
          label="Ostopaikka tai uudiskohteen katuosoite"
          helpText={t("productRegistrationLocation.helpText", "")}
          errorMsg={getErrorMsg("productRegistrationLocation")}
        />
        <Input
          name="productInstallDate"
          inputAttrs={{ type: "date", pattern: "d{4}-d{2}-d{2}" }}
          label={t("productInstallDate.label", "Date of purchase or delivery")}
          helpText={t("productInstallDate.helpText", "")}
          errorMsg={getErrorMsg("productInstallDate")}
        />
        <Input
          name="productSerial"
          label={t("productSerial.label", "Product serial number")}
          helpText={
            <a href={t("productSerial.helpUrl", "/en/contact/where-to-find-harvia-serial-number")} target="_blank">
              {t("productSerial.helpText", "Where to find serial numbers on Harvia products")}
            </a>
          }
          errorMsg={getErrorMsg("productSerial")}
        />
      </Fieldset>
      <UserContactFieldset errors={props.errors} />
      <Checkbox
        name="consent-pii-data"
        label={
          <span>
            {t(
              "consent-pii-data.label",
              "I agree to the storage and processing of my personal data (required).",
              "formCommon"
            )}{" "}
            <a href={t("consent-pii-data.labelUrlPath", "/en/register-description", "formCommon")} target="_blank">
              {t("consent-pii-data.labelUrlText", "Register description", "formCommon")}
            </a>
          </span>
        }
        required={true}
        errorMsg={getErrorMsg("consent-pii-data")}
      />
      <Button label={t("sendButton.label", "Approve & send", "formCommon")} type="submit" disabled={props.loading} />
      {props.loading && <LoadingSpinner loading={props.loading} />}
    </form>
  )
}

export default HeaterRegistrationForm
